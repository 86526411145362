
.App {
  font-family: sans-serif;
  text-align: center;
}

.tooltip {
  line-height: 5px;
  border: 1px solid #e5e5e5;
  background-color: rgba(255, 255, 255, 255);
  padding: 10px;

  text-align: center;
}

a:link {
  color: black;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: black;
  background-color: transparent;
  text-decoration: none;
}
a:hover {
  color: purple;
  background-color: transparent;
  text-decoration: underline;
}

